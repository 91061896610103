var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[(!_vm.user.documents_verified_at && _vm.isOwner)?_c('v-card',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"calc(100vh - 116px)"},attrs:{"elevation":"1"}},[_c('div',{staticClass:"text-center"},[_c('div',[_c('v-icon',{attrs:{"color":"grey","size":"164"}},[_vm._v("mdi-text-box-search-outline")])],1),_c('div',{staticClass:"grey--text title px-16 mt-5"},[_vm._v(" Пожалуйста подождите пока менеджер проверит ваши данные. "),_c('br'),_vm._v(" После проверки, на указанный вами E-mail будет отправлено подтверждение. ")])])]):_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"mx-0 my-0"},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"fill-height",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"bordered"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("profile.form.fields.change_password"))+" ")])]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t("profile.form.fields.change_password_data"))+" ")]),_c('v-card-text',{staticClass:"mt-3 px-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.errorMessages.old_password,"rules":[
                    _vm.rules.maxLengthRule(128),
                    _vm.rules.minLengthRule(8) ].concat( _vm.rules.requiredRule()
                  ),"type":_vm.showPassword.old ? 'text' : 'password',"dense":"","label":_vm.$t('profile.form.fields.old_password')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changePassword.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"pr-2",on:{"click":function($event){return _vm.toggleShowPassword('old')}}},[_vm._v(" "+_vm._s(_vm.showPassword.old ? "mdi-eye-off" : "mdi-eye")+" ")])]},proxy:true}]),model:{value:(_vm.model.old_password),callback:function ($$v) {_vm.$set(_vm.model, "old_password", $$v)},expression:"model.old_password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.errorMessages.password,"rules":[
                    _vm.rules.maxLengthRule(128),
                    _vm.rules.minLengthRule(8) ].concat( _vm.rules.requiredRule(),
                    [_vm.rules.notMatchPassword]
                  ),"type":_vm.showPassword.new ? 'text' : 'password',"dense":"","label":_vm.$t('profile.form.fields.new_password')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changePassword.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"pr-2",on:{"click":function($event){return _vm.toggleShowPassword('new')}}},[_vm._v(" "+_vm._s(_vm.showPassword.new ? "mdi-eye-off" : "mdi-eye")+" ")])]},proxy:true}]),model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.identityPassword, _vm.rules.maxLengthRule(128)],"type":_vm.showPassword.repeat ? 'text' : 'password',"dense":"","label":_vm.$t('profile.form.fields.confirm_password')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changePassword.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"pr-2",on:{"click":function($event){return _vm.toggleShowPassword('repeat')}}},[_vm._v(" "+_vm._s(_vm.showPassword.repeat ? "mdi-eye-off" : "mdi-eye")+" ")])]},proxy:true}]),model:{value:(_vm.model.password_confirmation),callback:function ($$v) {_vm.$set(_vm.model, "password_confirmation", $$v)},expression:"model.password_confirmation"}})],1),(_vm.isOwner)?_c('v-col',{staticClass:"pt-0 text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"py-0",staticStyle:{"height":"24px","min-width":"80px"},attrs:{"disabled":!_vm.model.password_confirmation ||
                      !_vm.model.password ||
                      !_vm.model.old_password,"color":"primary","depressed":"","small":""},on:{"click":_vm.changePassword}},[_vm._v(" "+_vm._s(_vm.$t('global_buttons.save'))+" ")])],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }