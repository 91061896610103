
import Vue from "vue";
import Chat from "@/components/chat/Chat.vue";

export default Vue.extend({
  name: "ProfileChat",

  components: { Chat },

  data: () => ({}),

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  methods: {
    toggleSidebar() {
      this.$emit("toggleSidebar");
    }
  }
});
