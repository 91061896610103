
import Vue from "vue";

import Swiper from "swiper";
import {Navigation} from 'swiper/modules';
import 'swiper/modules/navigation.css';
import 'swiper/modules/pagination.css';
import "swiper/swiper.css";

export default Vue.extend({
  data() {
    return {
      carouselImages: [
        {
          src: require('@/assets/resources/covers-01.png'),
          documentUrl: require('@/assets/resources/1_recommendations_for success_ENG_compressed.pdf'),
          alt: 'Recommendations for success eng'
        },
        {
          src: require('@/assets/resources/covers-02.png'),
          documentUrl: require('@/assets/resources/2_recommendations_for_success_RU_compressed.pdf'),
          alt: 'Recommendations for success ru'
        },
        {
          src: require('@/assets/resources/covers-03.png'),
          documentUrl: require('@/assets/resources/3.budgeting_tips_compressed.pdf'),
          alt: 'Budgeting tips'
        },
        {
          src: require('@/assets/resources/covers-04.png'),
          documentUrl: require('@/assets/resources/4_effective_credit_management_compressed.pdf'),
          alt: 'Effective credit management'
        },
        {
          src: require('@/assets/resources/covers-05.png'),
          documentUrl: require('@/assets/resources/5_debt_management_compressed.pdf'),
          alt: 'Debt management compressed'
        },
        {
          src: require('@/assets/resources/covers-06.png'),
          documentUrl: require('@/assets/resources/6_financial_planning_tips_compressed.pdf'),
          alt: 'Financial planning tips compressed'
        },
        {
          src: require('@/assets/resources/covers-07.png'),
          documentUrl: require('@/assets/resources/7_busines_navigating_business_credit_compressed.pdf'),
          alt: 'Business navigating business credit'
        },
        {
          src: require('@/assets/resources/covers-08.png'),
          documentUrl: require('@/assets/resources/8_cancellation_request_form.pdf'),
          alt: 'Cancellation Request Form'
        }
      ]
    };
  },
  mounted() {
    new Swiper('.swiper', {
      loop: false,
      modules: [Navigation],
      spaceBetween: 20,
      breakpoints: {
        567: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 3
        },
        960: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        },
        1400: {
          slidesPerView: 4
        },
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }
});
