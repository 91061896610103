
import Vue from "vue";
import errors from "@/router/modules/errors";
import {
  exactRule,
  maxLengthRule,
  minLengthRule,
  requiredRule
} from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import { LocaleMessages } from 'vue-i18n';

export default Vue.extend({
  name: "ProfilePersonal",

  directives: {
    mask
  },

  props: {
    user: {
      type: Object,
      required: true
    },
    isOwner: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    errorMessages: {} as any,
    model: {} as any,
    showPassword: {
      old: false as boolean,
      new: false as boolean,
      repeat: false as boolean
    } as { [value: string]: boolean }
  }),

  computed: {
    rules() {
      return {
        exactRule: exactRule.bind(this),
        requiredRule: requiredRule.bind(this),
        minLengthRule: minLengthRule.bind(this),
        maxLengthRule: maxLengthRule.bind(this),
        identityPassword: (v: string): string | boolean | LocaleMessages =>
          v === (this.model as any).password || this.$i18n.t('global_validation.password_mismatch'),
        notMatchPassword: (v: string): string | boolean | LocaleMessages =>
          v !== (this.model as any).old_password ||
            this.$i18n.t('global_validation.old_new_match')
      };
    }
  },

  methods: {
    async changePassword() {
      try {
        if ((this.$refs.form as any).validate()) {
          await this.$API.user().changePassword(this.model);

          await this.$store.dispatch(
            "alert/showSuccess",
            this.$i18n.t('global_messages.password_change_success')
          );

          this.model = {};
          (this.$refs.form as any).resetValidation();
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 3000);
        }
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    toggleShowPassword(type: string): void {
      this.showPassword[type] = !this.showPassword[type];
    },
    toggleSidebar() {
      this.$emit("toggleSidebar");
    }
  }
});
